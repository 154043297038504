import React, { useContext, useState, useCallback } from 'react'
import { statusLabelMaper } from '../../service/AppContext'
import { appContext } from '../../service/AppContext';
import { toast } from 'react-toastify';
import Input from '../Input/Input';
import { debounce } from 'lodash';
import ReactPlayer from 'react-player'



export default function VideoTableRow({ number, id, title, description, url,site_title, upload_date,post_id, status, handleUpdate, handleRemove }) {

  const [openEdit, setOpenEdit] = useState(false);
  const [preview, setPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [copyedLink, setCopyedLink] = useState(false)
  const [videoTitle, setVideoTitle] = useState(title)
  const [videoSiteTitle, setVideoSiteTitle] = useState(site_title)
  const [videoDesciption, setVideoDesciption] = useState(description ? description : '')
  const { breslevRequest } = useContext(appContext);

  const handleChangeTitle = (event) => {
    handleUpdateByElementName(event);
    setVideoTitle(event.target.value);
  }

  const handleChangeSiteTitle = (event) => {
    handleUpdateByElementName(event);
    setVideoSiteTitle(event.target.value);
  }

  const handleChangeDescription = (event) => {
    handleUpdateByElementName(event);
    setVideoDesciption(event.target.value);
  }

  const handleCopyLink = () => {
    setCopyedLink(true);
    navigator.clipboard.writeText(url);
    setTimeout(() => { setCopyedLink(false) }, 1000)

  }

  const handleUpdateByElementName = useCallback(debounce(async (event) => {
    let keyName = event.target.name;
    let updateObj = { id: id, [keyName]: event.target.value }
    let response = await breslevRequest({ method: 'put', route: '/video/update', body: updateObj })
    if (response?.status === 204) {
      if (keyName === 'title')
        toast('שם הסרטון עודכן');
      if (keyName === 'site_title')
        toast('כותרת הסרטון עבור האתר עודכנה');
      if (keyName === 'description')
        toast('תיאור הסרטון עודכן');
      handleUpdate(updateObj);
    }
    else
      toast('שגיאה בעדכון פרטי סרטון');
  }, 1000, { leading: false, trailing: true })
    , [id])

  const handleRemoveElement = (async () => {
    setIsLoading(true)
    let response = await breslevRequest({ method: 'delete', route: '/video/delete/' + id })
    if (response?.status === 204) {
      toast('הסרטון הוסר בהצלחה');
      handleRemove();
    }
    else {
      toast('מחיקת הסרטון נכשלה , נסה שנית עוד מספר דקות');
    }
    setIsLoading(false)
  })



  return (
    <>
      <tr>
        <td>
          {number}
        </td>
        {(openEdit) ?
          <>
            <td>
              <Input name='title' value={videoTitle} onChange={handleChangeTitle} disabled={isLoading} />
            </td>
            <td>
              <Input name='site_title' value={videoSiteTitle} onChange={handleChangeSiteTitle} disabled={isLoading} />
            </td>
            <td>
              <textarea name='description' rows={3} placeholder='תיאור הסרטון' onChange={handleChangeDescription} value={videoDesciption} disabled={isLoading} />
            </td>
          </>
          :
          <>
            <td>
              {title}
            </td>
            <td>
              {site_title}
            </td>
            <td>
              {description}
            </td>
            
          </>
        }
        <td>
          {upload_date}
        </td>
        <td>
          {
            <span className="copy-text-action" onClick={handleCopyLink}><img src="./icons/copy.svg" alt="copy url to clipboard" />העתק
              {
                (copyedLink) &&
                <span class="copy-text-notification" ><span class="block-snippet">הועתק!</span></span>
              }
            </span>
          }
        </td>
        <td>
          {statusLabelMaper[status]}
        </td>
        <td>
          {post_id}
        </td>
        <td >
          <div className='edit-col'>
            <button className='button' onClick={() => setPreview(prev => !prev)} disabled={isLoading} >{(preview) ? 'הסתר' : 'צפייה'} </button>
            <button className='button' onClick={() => setOpenEdit(prev => !prev)} disabled={isLoading} >{(openEdit) ? 'סגירה' : 'ערוך'} </button>
            <button className='button button-error button-spinner' onClick={handleRemoveElement} disabled={isLoading}>מחיקה
              {(isLoading) && <img className='spinner' src="./circle-notch-solid.svg" alt="button spinner" />}
            </button>
          </div>
        </td>
      </tr>
      <tr className='preview'>
        {(preview) &&
          <td colSpan='100%' >
            <ReactPlayer url={url} controls={true} playing={true} style={{ margin: '20px auto' }} width={'100%'}  />
          </td>}
      </tr>
    </>

  )
}
